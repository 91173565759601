import React from 'react';
import { Link, graphql } from 'gatsby';
import ReactPlayer from 'react-player';
import ScrollAnimation from 'react-animate-on-scroll';
import Slider from "react-slick";
import Layout from '../components/layout'
import SEO from "../components/seo";
import "animate.css/animate.compat.css";
import Header from '../components/headerEn';
import Footer from '../components/footerEn';
import Contact from '../components/contactEn';
import Map from '../images/worldmap.svg';

class IndexEnPage extends React.Component {
  render() {
    const settings = {
      dots: false,
      infinite: true,
      autoplay: true,
      fade: true,
      speed: 3500,
      pauseOnHover: false,
    };
    return (
      <Layout>
        <SEO title="Homepage" />
        <Contact />
        <Header />  
        <div className="slider-banner">     
        <Slider {...settings}>
          <div className="banner">
            <div className="banner-second-main">
              <div className="container in-banner w-container">
                <div className="flex-row make-verical w-row">
                  <div className="w-col w-col-6 w-col-stack">
                  </div>
                  <div className="w-clearfix w-col w-col-6 w-col-stack">
                    <div className="hero-text-box">
                      <h3 style={{ color:"white" }}>K Laser Technology</h3>
                      <h1 className="main-banner-title">Security Packaging Innovator</h1>
                      <div className="top-margin medium">
                        <Link to="/en/products/" className="link-wrapper big-link w-inline-block">
                          <div className="link-text">Explore Further</div>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>    
          </div>
          <div className="banner2">
            <div className="banner-second-main">
              <div className="container in-banner w-container">
                <div className="flex-row make-verical w-row">
                  <div className="w-col w-col-6 w-col-stack">
                  </div>
                  <div className="w-clearfix w-col w-col-6 w-col-stack">
                    <div className="hero-text-box">
                      <h3 style={{ color:"white" }}>K Laser Technology</h3>
                      <h1 className="main-banner-title">Security Packaging Innovator</h1>
                      <div className="top-margin medium">
                        <Link to="/en/products/" className="link-wrapper big-link w-inline-block">
                          <div className="link-text">Explore Further</div>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div> 
          </div>
          <div className="banner3">
            <div className="banner-second-main">
              <div className="container in-banner w-container">
                <div className="flex-row make-verical w-row">
                  <div className="w-col w-col-6 w-col-stack">
                  </div>
                  <div className="w-clearfix w-col w-col-6 w-col-stack">
                    <div className="hero-text-box">
                      <h3 style={{ color:"white" }}>K Laser Technology</h3>
                      <h1 className="main-banner-title">Security Packaging Innovator</h1>
                      <div className="top-margin medium">
                        <Link to="/en/products/" className="link-wrapper big-link w-inline-block">
                          <div className="link-text">Explore Further</div>
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div> 
          </div>
        </Slider>
        </div>
        <div className="banner3 mobile-banner">
          <div className="banner-second-main">
            <div className="container in-banner w-container">
              <div className="flex-row make-verical w-row">
                <div className="w-col w-col-6 w-col-stack">
                </div>
                <div className="w-clearfix w-col w-col-6 w-col-stack">
                  <div className="hero-text-box">
                    <h3 style={{ color:"white" }}>K Laser Technology</h3>
                    <h1 className="main-banner-title">Security Packaging Innovator</h1>
                    <div className="top-margin medium">
                      <Link to="/en/products/" className="link-wrapper big-link w-inline-block">
                        <div className="link-text">Explore Further</div>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> 
        </div>
        <section id="Scroll" className=" page-content section more-padding">
          <div className="container w-container">
            <ScrollAnimation animateIn='fadeInUp' animateOnce={true}>
              <h2>Welcome to <br />K Laser Technology</h2>
              <h3 style={{ paddingBottom:"1em" }}>Established in 1988, K Laser Technology Inc. has been a pioneer in optical hologram origination, hologram embossing and the material deposition process.</h3>
            </ScrollAnimation>
            <ScrollAnimation animateIn='fadeIn' animateOnce={true}>
              <Link to="/en/about/" className="link-wrapper big-link w-inline-block">
                <div className="link-text">Learn More</div>
              </Link>
            </ScrollAnimation>
          </div>
        </section>

        <section className="section page-content more-padding" style={{ background:"#1c1c1f"}}>
          <div className="container w-container">
            <ScrollAnimation animateIn='fadeInUp' animateOnce={true}>
              <h2 className="invert-text-color">Introducing <br />Tru-Seamless Technology</h2>
              <h3 style={{ paddingBottom:"1em" }}>The industry’s latest innovation in thin film technology, helping customers to optimize productivity and efficiency.</h3>
            </ScrollAnimation>
            <ScrollAnimation animateIn='fadeIn' animateOnce={true}>
              <Link to="/en/truseamless/" className="link-wrapper big-link w-inline-block">
                <div className="link-text">Learn More</div>
              </Link>
            </ScrollAnimation>
            <ScrollAnimation animateIn='fadeIn' animateOnce={true}>
              <div className="video" style={{ marginTop:"100px" }}>
                <ReactPlayer
                  url="/static/videos/F019_Double_Lens_compressed_hojdyy.mp4"
                  playing
                  loop
                  muted
                  width="480px"
                  height="480px"
                  style={{ margin: "0 auto" }}
                  playbackRate={2}
                />          
              </div>
            </ScrollAnimation>
          </div>
        </section>

        <section className="section page-content">
          <div className="container w-container">
            <ScrollAnimation animateIn='fadeInUp' animateOnce={true}>
              <div className="w-row">
                <div className="w-col w-col-7 w-col-stack">
                  <img style={{ marginTop:"2em" }} src={Map} alt="map" />
                </div>
                <div className="w-col w-col-5 w-col-stack" style={{ padding:"50", marginTop:"3em" }}>
                  <h3 style={{ color:"#232323" }}>K Laser Worldwide</h3>
                  <p>K Laser Technology has an extensive network of manufacturing, regional sales offices and distribution centers across the globe. <br />In addition, we maintain strong partnerships with well-known local agents in many countries, guaranteeing exceptional customer service and satisfaction.</p>
                  <Link to="/en/about#contact" className="link-wrapper big-link w-inline-block">
                    <div className="link-text">Learn More</div>
                  </Link>
                </div>
              </div>
            </ScrollAnimation>
          </div>
        </section>
        <section className="mobile-contact-form">
          <form action="https://formspree.io/xqkzwzgb" method="POST">
            <div className="w-row">
              <div className="w-col w-col-6">
                <input className="text-field w-input" placeholder='First Name' type='text' name='first' />
              </div>
              <div className="w-col w-col-6">
                <input className="text-field w-input" placeholder='Last Name' type='text' name='last' />
              </div>
            </div>
            <div className="w-row">
              <div className="w-col w-col-6">
                <input className="text-field w-input" placeholder='Email' type='email' name='email' />
              </div>
              <div className="w-col w-col-6">
                <input className="text-field w-input" placeholder='Phone' type='text' name='phone' />
              </div>
            </div>
            <div className="w-row">
              <div className="w-col w-col-6">
                <input className="text-field w-input" placeholder='Company' type='text' name='company' />
              </div>
              <div className="w-col w-col-6">
                <input className="text-field w-input" placeholder='Position' type='text' name='position' />
              </div>
            </div>
            <div className="w-row">
              <div className="w-col w-col-6">
                <input className="text-field w-input" placeholder='Country' type='text' name='country' />
              </div>
              <div className="w-col w-col-6">
                <textarea className="text-field area w-input"label='Message' name="message" placeholder='Ask us anything...' />
              </div>
            </div>
            <div className="w-col w-col-6" style={{ textAlign: "center" }}>
              <button className="button" type="submit">Submit</button>
            </div>
          </form>
        </section>

        <Footer />
      </Layout>
    )
  }
}

export default IndexEnPage;

export const pageQuery = graphql`  
  query IndexEnQuery {
    allStrapiProduct {
      edges {
        node {
          id
          language
          description
        }
      }
    }
  }
`
